// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bg-top{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0 0,top;background-position:-150px;background-repeat:repeat-x,no-repeat;margin-top:-10px}@media (max-width:1199.98px){.bg-top{background-position:-300px;background-size:auto 100%,100% auto}}@media (max-width:991.98px){.bg-top{background-position:-350px;background-size:auto 100%,100% auto}}@media (max-width:767.98px){.bg-top{background-position:-300px;background-size:auto 100%,100% auto}}@media (max-width:575.98px){.bg-top{background-position:-290px;background-size:auto 100%,100% auto}}.bg-color-01{background:linear-gradient(0deg,#fa6800,#e35e00)}.bg-color-02{background:linear-gradient(0deg,#e4e6d4,#d7d9c8)}.bg-color-03{background:#a5dcd9}.image-fluid{max-width:80%}.h2toptext{font-size:44px}@media (max-width:1199.98px){.h2toptext{font-size:40px}}@media (max-width:991.98px){.h2toptext{font-size:34px}}@media (max-width:767.98px){.h2toptext{font-size:30px}}@media (max-width:575.98px){.h2toptext{font-size:25px}}.h2subtitle{font-size:32px}@media (max-width:1199.98px){.h2subtitle{font-size:30px}}@media (max-width:991.98px){.h2subtitle{font-size:30px}}@media (max-width:767.98px){.h2subtitle{font-size:28px}}@media (max-width:575.98px){.h2subtitle{font-size:26px}}.h3subtitle{font-size:26px}@media (max-width:1199.98px){.h3subtitle{font-size:24px}}@media (max-width:991.98px){.h3subtitle{font-size:24px}}@media (max-width:767.98px){.h3subtitle{font-size:22px}}@media (max-width:575.98px){.h3subtitle{font-size:20px}}.h5subtext{font-size:20px}@media (max-width:1199.98px){.h5subtext{font-size:20px}}@media (max-width:991.98px){.h5subtext{font-size:20px}}@media (max-width:767.98px){.h5subtext{font-size:18px}}@media (max-width:575.98px){.h5subtext{font-size:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
